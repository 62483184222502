<template>
  <div
    v-if="
      $route.path !== '/login' && $route.path !== '/estado-obra' && isDataReady
    "
  >
    <sidenav
      :orientation="orientation"
      :class="curClasses"
      style="background:  #F49929 !important;"
    >
      <!-- Se itera sobre el menu de usuario y se muestran sus opciones -->
      <div
        class="sidenav-inner"
        :class="{ 'py-1': orientation !== 'horizontal' }"
      >
        <sidenav-router-link
          v-for="(items, index) in menuUsuario"
          :key="items.id"
          :id="index"
          :icon="items.icon"
          :to="{ path: items.href }"
          :exact="true"
          >{{ items.name }}</sidenav-router-link
        >
      </div>
    </sidenav>
  </div>
</template>
<style>
.sidenav-link {
  color: white !important;
}
.sidenav-item:hover {
  background-color: #e09170 !important;
}
</style>

<script>
// Importación de función que recoge la info o bien de sessionStorage o de localStorage
import { getFromStorage } from "../utilidades/storage.js";
import { getLanguageData } from "../utilidades/setLanguageJSONLocalStorage.js";
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    },
    idioma: {
      type: String
    }
  },
  data() {
    return {
      isDataReady: false,
      language: null,
      menuUsuario: {}
    };
  },
  watch: {
    // se escucha el cambio de idioma cuando se hace click en una bandera y se trae desde localStorage
    // el menu en el nuevo idioma seleccionado.
    idioma: function(newValue) {
      if (newValue) {
        this.isDataReady = false;
        this.menuUsuario = getLanguageData("menus", newValue).menuUsuario;
        this.isDataReady = true;
      }
    }
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    }
  },
  async created() {
    // se trae el lenguage y el menu desde localStorage
    this.language = localStorage.getItem("language");
    this.menuUsuario = JSON.parse(getFromStorage("l10n-menu"));
    this.isDataReady = true;
  }
};
</script>
